import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"

import { Autoplay, Pagination } from "swiper/modules"

function Values() {
  return (
     <Swiper
    slidesPerView={3}
    spaceBetween={20}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination]}
    className="mySwiper"
    breakpoints={{
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 768px
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      // when window width is >= 1024px
      1440: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }}
  >
      <SwiperSlide>
        <div className=" card__orb card__orb-grey card__no-bgcol">
          <div className="card__orb-img-wrap">
            <img
              src="./images/val-1.jpg"
              alt=""
              className="card__orb-main-img"
            />
          </div>
          <div className="card__orb-body">
            <h3 className="card__orb-title">
              Experienced <br /> Team
            </h3>
            <p>
              We bring together a senior team of specialists with a wide range
              of expertise, having worked with government and corporate clients
              globally.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className=" card__orb card__orb-grey card__no-bgcol">
          <div className="card__orb-img-wrap">
            <img
              src="./images/val-2.jpg"
              alt=""
              className="card__orb-main-img"
            />
          </div>
          <div className="card__orb-body">
            <h3 className="card__orb-title">Cybersecurity Experience</h3>
            <p>
              Orbit has carried out similar kinds of assignments globally, as
              well as regionally which features the experience we will bring as
              part of this proposition.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className=" card__orb card__orb-grey card__no-bgcol">
          <div className="card__orb-img-wrap">
            <img
              src="./images/val-3.jpg"
              alt=""
              className="card__orb-main-img"
            />
          </div>
          <div className="card__orb-body">
            <h3 className="card__orb-title">
              Certified <br /> Partners
            </h3>
            <p>
              Orbit is globally recognized as an information security leader by
              organizations like PwC and Accenture.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className=" card__orb card__orb-grey card__no-bgcol">
          <div className="card__orb-img-wrap">
            <img
              src="./images/val-2.jpg"
              alt=""
              className="card__orb-main-img"
            />
          </div>
          <div className="card__orb-body">
            <h3 className="card__orb-title">
              National Digital &amp; Security Agendas
            </h3>
            <p>
              Orbit delivers across multiple dimensions of national digital and
              cyber resilience agendas and is aware of future Saudi
              requirements.
            </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default Values
