import React, { useState } from "react"
import emailjs from "emailjs-com"

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    company: "",
    telephone: "",
    message: "",
  })

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    email: false,
    company: false,
    telephone: false,
    message: false,
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setFormErrors({ ...formErrors, [name]: value.trim() === "" })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (Object.values(formErrors).some((err) => err)) {
      alert("Please fill out all fields correctly.")
      return
    }

    emailjs
      .sendForm(
        "service_zdf8ijh",
        "template_i6hom5c",
        e.target,
        "fXYaIxVqIu8gCt5a3"
      )
      .then(
        (result) => {
          console.log(result.text)
          alert("Your message has been sent successfully!")
          setFormData({
            fullName: "",
            email: "",
            company: "",
            telephone: "",
            message: "",
          })
        },
        (error) => {
          console.log(error.text)
          alert("An error occurred. Please try again later.")
        }
      )
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className={`mb-3 ${formErrors.fullName ? "has-error" : ""}`}>
            <input
              type="text"
              placeholder="Full Name"
              className="form-control"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            {formErrors.fullName && (
              <div className="invalid-feedback">
                Please enter your full name.
              </div>
            )}
          </div>
          <div className={`mb-3 ${formErrors.email ? "has-error" : ""}`}>
            <input
              type="email"
              placeholder="Your Email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && (
              <div className="invalid-feedback">
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div className={`mb-3 ${formErrors.company ? "has-error" : ""}`}>
            <input
              type="text"
              placeholder="Company"
              className="form-control"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
            {formErrors.company && (
              <div className="invalid-feedback">
                Please enter your company name.
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className={`mb-3 ${formErrors.telephone ? "has-error" : ""}`}>
            <input
              type="tel"
              placeholder="Telephone"
              className="form-control"
              id="telephone"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
              required
            />
            {formErrors.telephone && (
              <div className="invalid-feedback">
                Please enter a valid telephone number.
              </div>
            )}
          </div>
          <div className={`mb-3 ${formErrors.message ? "has-error" : ""}`}>
            <textarea
              className="form-control"
              placeholder="Message"
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {formErrors.message && (
              <div className="invalid-feedback">Please enter your message.</div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button type="submit" className="button button-white">
            Send
          </button>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
